'use client';
import { motion } from 'framer-motion';
import { Mic, FileText, Globe, Users } from 'lucide-react';
import Link from 'next/link';
import { useEffect } from 'react';
import { useState } from 'react';

// Add this component to handle client-side initialization
const ClientOnly = ({ children }: { children: React.ReactNode }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return <>{children}</>;
};

export function LandingPage() {
  return (
    <ClientOnly>
      {/* Overall background updated to primary brand color */}
      <div className='flex flex-col min-h-screen bg-teal-700'>
        <main className='flex-1'>
          {/* Hero Section */}
          <section
            className='w-full min-h-screen flex items-center'
            style={{
              backgroundImage: "url('/bg.png')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='container mx-auto px-4 md:px-6 max-w-5xl'>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className='flex flex-col items-center space-y-8 text-center'
              >
                <h1 className='text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl max-w-4xl text-[#EFF5EE]'>
                  Transcribe, <span className='text-[#111610]'>Translate</span>{' '}
                  and Broadcast Speeches with Ease
                </h1>
                <p className='max-w-2xl text-[#EFF5EE] opacity-90 md:text-xl lg:text-2xl'>
                  <span className='text-[#111610]'>TalkTranslate.ai</span>: The
                  ultimate voice transcription app for community-based
                  organizations, conferences, and events. Capture, transcribe,
                  translate and broadcast your speeches effortlessly &amp; in
                  realtime to your audience, in{' '}
                  <span className='text-[#111610]'>multiple languages</span>.
                </p>
                <div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4'>
                  <Link
                    href='#how-it-works'
                    className='bg-[#ACDFDD] hover:bg-[#EFF5EE] text-[#111610] px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105 font-medium'
                  >
                    Get Started
                  </Link>
                  <Link
                    href='#features'
                    className='border border-[#EFF5EE] text-[#EFF5EE] hover:bg-[#EFF5EE] hover:text-[#111610] px-8 py-3 rounded-full transition-all duration-300 font-medium'
                  >
                    Learn More
                  </Link>
                  <Link
                    href='https://calendly.com/sas70/translate-30-min'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='bg-[#EFF5EE] hover:bg-white text-[#111610] px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105 font-medium'
                  >
                    Contact Us
                  </Link>
                </div>
              </motion.div>
            </div>
          </section>

          {/* Features Section */}
          <section id='features' className='w-full py-20 md:py-32 bg-[#EFF5EE]'>
            <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
              <h2 className='text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16 text-[#111610]'>
                Key Features
              </h2>
              <div className='grid gap-12 md:grid-cols-2 lg:grid-cols-3'>
                {[
                  {
                    icon: FileText,
                    title: 'Accurate Transcription',
                    description:
                      'State-of-the-art AI technology ensures precise transcription & translation of most languages including Arabic.',
                  },
                  {
                    icon: Globe,
                    title: 'Multi-language Support',
                    description:
                      'Transcribe and translate talks in multiple languages, including Arabic.',
                  },
                  {
                    icon: Users,
                    title: 'Easy Sharing',
                    description:
                      'Get inclusive &amp; closer to your audience by realtime multi-language translation of talks without any hassle.',
                  },
                ].map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='flex flex-col items-center text-center p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white'
                  >
                    <feature.icon className='h-16 w-16 text-teal-700 mb-6' />
                    <h3 className='text-2xl font-bold mb-4 text-[#111610]'>
                      {feature.title}
                    </h3>
                    <p className='text-[#111610] opacity-80'>
                      {feature.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>

          {/* How It Works Section */}
          <section id='how-it-works' className='w-full py-20 md:py-32 bg-white'>
            <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
              <h2 className='text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16 text-[#111610]'>
                How It Works
              </h2>
              <div className='grid gap-12 md:grid-cols-2 lg:grid-cols-3'>
                {[
                  {
                    icon: Users,
                    title: 'Sign-up',
                    description:
                      'Create your account to get started with our services.',
                  },
                  {
                    icon: Mic,
                    title: 'Click on Streaming',
                    description: (
                      <>
                        A PIN is generated—share it with your audience so they
                        can join at{' '}
                        <Link
                          href='https://www.talksforall.com'
                          target='_blank'
                          rel='noopener noreferrer'
                          className='text-blue-600'
                        >
                          https://www.talksforall.com
                        </Link>
                        .
                      </>
                    ),
                  },
                  {
                    icon: FileText,
                    title: 'Start Recording',
                    description:
                      'Click Start recording (and make sure you allow the use of mic and unmute).',
                  },
                ].map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='flex flex-col items-center text-center p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 bg-[#EFF5EE]'
                  >
                    <step.icon className='h-16 w-16 text-teal-700 mb-6' />
                    <h3 className='text-2xl font-bold mb-4 text-[#111610]'>
                      {step.title}
                    </h3>
                    <p className='text-[#111610] opacity-90'>
                      {step.description}
                    </p>
                  </motion.div>
                ))}
              </div>
            </div>
          </section>

          {/* Testimonials Section */}
          <section
            id='testimonials'
            className='w-full py-20 md:py-32 bg-[#ACDFDD]'
          >
            <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
              <h2 className='text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16 text-[#111610]'>
                Trusted by reputable organisations...
              </h2>
              {/* <div className='grid gap-8 md:grid-cols-2 lg:grid-cols-3'>
                {[
                  {
                    quote:
                      "TalkTranslate has revolutionized how we share our Friday khutbahs with our community. It's an invaluable tool for dawah.",
                    author: '----------------------------------',

                    // author: 'Imam Abdullah, New York Central Mosque',
                  },
                  {
                    quote:
                      'The multi-language support has helped us reach a wider audience and break down language barriers in our diverse community.',
                    author: '----------------------------------',
                    // author: 'Ustadh Yusuf, London Islamic Center',
                  },
                  {
                    quote:
                      'The accuracy of the translation, especially with Islamic terminology, is impressive. It saves us precious time & resources.',
                    author: '----------------------------------',

                    // author: 'Sister Aisha, Toronto Masjid',
                  },
                ].map((testimonial, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className='bg-[#EFF5EE] p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300'
                  >
                    <p className='text-[#111610] opacity-90 mb-6 italic'>
                      &ldquo;{testimonial.quote}&rdquo;
                    </p>
                    <p className='font-bold text-teal-700'>
                      - {testimonial.author}
                    </p>
                  </motion.div>
                ))}
              </div> */}
            </div>
          </section>
        </main>

        {/* Footer */}
        <footer className='py-8 w-full border-t border-[#696969] bg-white'>
          <div className='container mx-auto px-4 md:px-6 max-w-6xl'>
            <div className='flex flex-col items-center space-y-4 text-center md:flex-row md:justify-between md:space-y-0'>
              <p className='text-sm text-[#111610] opacity-80 md:max-w-[50%]'>
                For support, please contact us at{' '}
                <a
                  href='mailto:Omar@TalkTranslate.ai'
                  className='underline hover:text-teal-700'
                >
                  Omar@TalkTranslate.ai
                </a>
              </p>
              <nav className='flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4 md:space-x-6'>
                <Link
                  className='text-sm text-[#111610] opacity-80 hover:opacity-100 transition-opacity'
                  href='https://docs.google.com/document/d/10maZPdt0u9SZT6bZtsROsNFE4VB2hWuJzmHldAWWPLI/edit?usp=sharing'
                >
                  Terms of Service
                </Link>
                <Link
                  className='text-sm text-[#111610] opacity-80 hover:opacity-100 transition-opacity'
                  href='https://docs.google.com/document/d/1kk1vw2dqT_RiS3O4spJ_9tMpRD7kGcbVpHqLoRjOLNI/edit?usp=sharing'
                >
                  Privacy
                </Link>
              </nav>
            </div>
          </div>
        </footer>
      </div>
    </ClientOnly>
  );
}
